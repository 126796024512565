<template>
<svg :width="width" :height="height" :fill="color" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5m0 0a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5m14.91 6.58-9-9a2 2 0 0 0 -1.41-.58h-7a2 2 0 0 0 -2 2v7a2 2 0 0 0 .59 1.42l.41.4a5.62 5.62 0 0 1 2.08-.74l-1.08-1.08v-7h7l9 9-7 7-1.08-1.08a5.57 5.57 0 0 1 -.74 2.08l.41.41a2 2 0 0 0 1.41.59 2 2 0 0 0 1.41-.59l7-7a2 2 0 0 0 .59-1.41 2 2 0 0 0 -.59-1.42m-14.91-6.58a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0 -1.5-1.5m3.5 14h-3v3h-2v-3h-3v-2h3v-3h2v3h3z"/></svg>
</template>
<script>
export default {
  name: 'ascIconSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
